@use "./config/" as *;
@forward "./main-scss/";
@forward "./partials/form-common";

.waf-photograph-year {
    @extend %p-8-4;
    .custom-form {
        @extend %mb-8;
        @extend %p-4;
        @extend %half-radius;
        @extend %pure-white-900-bg;
        .btn-outline {
            &.disabled {
                cursor: not-allowed;
                border: 0;
                @extend %neutral-300-bg;
            }
            &[type="button"]:disabled {
                cursor: not-allowed;
                border: 0;
                @extend %neutral-300-bg;
                .btn-text {
                    &::before {
                        content: unset;
                    }
                    &::after {
                        @include icon(drop-arrow);
                    }
                }
            }
        }
        .form-group {
            &.error {
                .form-input {
                    border: .1rem solid var(--error-900);
                }
            }
        }
        .form-input {
            text-transform: none;
        }
    }
    .title {
        min-height: auto;
        @extend %mb-4;
        @extend %uppercase;
        @extend %secondary-800;
        @extend %font-16-pb;
    }
    .text-link {
        font-weight: 700;
        @extend %primary-900;
    }
    .acknowledge-checkbox {
        .text {
            cursor: pointer;
        }
    }
    .user-upload {
        .form-element {
            border: .1rem solid var(--neutral-1000);
            min-height: 14rem;
            @extend %relative;
            @extend %half-radius;
            &:before {
                content: '';
                width: calc(100% - var(--space-8));
                height: calc(100% - var(--space-8));
                border: .2rem dashed var(--neutral-200);
                @extend %half-radius;
                @include position(var(--space-4),null,null,var(--space-4));
            }
        }
        .file-upload {
            opacity: .8;
            @extend %pos-center;
            @extend %flex-column-n-c;
            @extend %gap-2;
            @extend %neutral-800;
            @extend %capitalize;
            @extend %font-14-pr;
            &:before {
                content: '';
                width: 2.5rem;
                height: 2.5rem;
                @extend %flex;
                @include background(null, "icons/file-upload.png", center / contain no-repeat);
            }
        }
        .form-input-upload {
            opacity: 0;
            cursor: pointer;
            @extend %w-100;
            @extend %h-100;
            @extend %pos-tr;
        }
        &.error {
            .form-element {
                border: .1rem solid var(--error-900);
            }
        }
    }
    .body {
        .photograph {
            &-item {
                @extend %w-100;
                @extend %pure-white-900-bg;
                @extend %half-radius;
                &:not(:last-child) {
                    @extend %mb-4;
                }
                &.photograph-empty {
                    .img-box {
                        @include shimmer(100%, 100%, null);
                    }
                    .title {
                        @include shimmer(100%, 3.2rem, null);
                    }
                    .meta {
                        @include shimmer(100%, 1.4rem, null);
                    }
                    .photograph-status {
                        @extend %d-none;
                    }
                }
            }
            &-thumbnail {
                @extend %relative;
                .img-box {
                    border-radius: var(--half-radius) var(--half-radius) 0 0;
                    aspect-ratio: 1/1;
                    @extend %neutral-100-bg;
                }
                img {
                    object-fit: contain;
                }
            }
            &-content {
                @extend %p-4;
                .title {
                    text-transform: none;
                    min-height: 3.4rem;
                    @extend %neutral-800;
                    @extend %font-14-pb;
                    @include truncate-text(2);
                }
            }
            &-status {
                min-height: 3.3rem;
                width: max-content;
                @extend %pos-tr;
                @extend %flex-n-c;
                @extend %neutral-800;
                @extend %px-3;
                @extend %font-10-pm;
                @extend %capitalize;
                @extend %half-radius;
                @extend %primary-300-bg;
            }
            &-meta {
                @extend %flex-column;
                @extend %gap-1;
                .meta {
                    @extend %neutral-800;
                    @extend %font-12-pm;
                }
            }
        }
        .no-data {
            @extend %text-center;
            .text {
                @extend %neutral-600;
                @extend %font-16-pb;
            }
        }
    }
    &.form-hide {
        .waf-head,.custom-form {
            @extend %d-none;
        }
    }
}
.modal-photograph-preview {
    .photograph {
        &-item {
            @extend %pure-white-900-bg;
            @extend %half-radius;
            &:not(:last-child) {
                @extend %mb-4;
            }
        }
        &-thumbnail {
            @extend %relative;
            .img-box {
                aspect-ratio: 1/1;
                @extend %neutral-100-bg;
            }
            img {
                object-fit: contain;
            }
        }
        &-content {
            @extend %text-left;
            // @extend %p-4;
            .title {
                text-transform: none;
                @extend %neutral-800;
                @extend %font-14-pb;
                @include truncate-text(2);
            }
        }
        &-status {
            min-height: 3.3rem;
            width: max-content;
            @extend %pos-tr;
            @extend %flex-n-c;
            @extend %neutral-800;
            @extend %px-3;
            @extend %font-10-pm;
            @extend %capitalize;
            @extend %half-radius;
            @extend %primary-300-bg;
        }
        &-meta {
            @extend %flex-column;
            @extend %gap-2;
            .meta {
                @extend %neutral-800;
                @extend %font-12-pm;
            }
        }
    }
}
@include mq(col-lg) {
    .waf-photograph-year {
        .waf-head {
            .title {
                font-size: 2.4rem;
            }
        }
        .user-upload,.acknowledge-checkbox {
            width: 100%;
        }
        .acknowledge-checkbox {
            .checkbox-container {
                width: max-content;
            }
        }
        .head {
            .title {
                font-size: 2.4rem;
            }
        }
        .body {
            background: var(--pure-white-900);
            padding: var(--space-4);
            border-radius: var(--half-radius);
            .no-data {
                .text {
                    font-size: 2.4rem;
                }
            }
            .photograph {
                &-listing {
                    gap: var(--space-4);
                    @include flex-config(flex);
                }
                &-item {
                    background: var(--neutral-50);
                    &:not(:last-child) {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
}